import { Box } from "@mui/material";
import jwt from "jwt-decode";
import { Auth } from "aws-amplify";
import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
const SocialLogin = ({ label }: { label?: string }) => {
	const { handleSocialLogin } = useAuth();
	const { showSnackbar } = useAlert();
	const getUser = async (token: any): Promise<void> => {
		try {
			await Auth.currentAuthenticatedUser();
			await handleSocialLogin(token);
		} catch (error) {
			console.error(error);
			console.log("Not signed in");
		}
	};
	// // Exchange Google token for temporary AWS credentials
	const getAWSCredentials = async (credential: string) => {
		try {
			const token = jwt(credential) as any;
			const user = {
				email: token.email,
				name: token.name,
			};
			const loggedInUser = await Auth.federatedSignIn(
				"google",
				{ token: credential, expires_at: token.exp },
				user
			);
			if (loggedInUser) {
				getUser(token);
			}
		} catch (error: any) {
			console.log("getAWSCredentials error", error);
			showSnackbar(error?.message, error);
		}
	};

	return (
		<GoogleOAuthProvider clientId="832108443867-fu9hmp1hbqqpdp8eb8p3h327n1683vka.apps.googleusercontent.com">
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<GoogleLogin
					onSuccess={(credentialResponse) => {
						if (credentialResponse?.credential)
							getAWSCredentials(credentialResponse.credential);
					}}
					onError={() => {
						console.log("Login Failed");
					}}
				/>
			</Box>
		</GoogleOAuthProvider>
	);
};

export default SocialLogin;
