import {
	Password,
	VisibilityOutlined,
	VisibilityOffOutlined,
	EmailOutlined,
} from "@mui/icons-material";
import {
	Box,
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography,
	styled,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

interface IFormField {
	name: string;
	label: string;
	placeholder?: string;
	required?: boolean;
	type?: string;
	disabled?: boolean;
	multiline?: boolean;
	minRows?: number;
	marginBottom?: string;
}

export const FormField: FC<IFormField> = ({
	name,
	label,
	placeholder = "",
	type = "text",
	disabled,
	multiline,
	minRows,
	marginBottom,
}) => {
	const {
		errors,
		touched,
		values,
		handleChange,
		handleBlur: formikHandleBlur,
	} = useFormikContext<any>();
	const [showPassword, setShowPassword] = useState(false);
	const inputRef = React.useRef<HTMLInputElement | null>(null);

	const handleClickShowPassword = () => {
		setShowPassword((show) => !show);
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	return (
		<FormInputWrapper>
			{/* <Label variant={`body2`}>{label}</Label> */}
			{type !== "checkbox" ? (
				<Field
					fullWidth
					sx={{
						backgroundColor: "rgba(93, 99, 122, 0.10)",
						marginBottom: marginBottom ? marginBottom : "16px",
					}}
					name={name}
					type={
						type === "password"
							? showPassword
								? "text"
								: "password"
							: type
					}
					value={values[name]}
					size="medium"
					onChange={handleChange}
					onBlur={(e) => {
						formikHandleBlur(e);
					}}
					error={touched[name] && Boolean(errors[name])}
					placeholder={placeholder}
					disabled={disabled}
					multiline={multiline}
					minRows={minRows}
					inputRef={inputRef}
					endAdornment={
						type === "password" ? (
							<InputAdornment position="end" variant="outlined">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? (
										<VisibilityOffOutlined />
									) : (
										<VisibilityOutlined />
									)}
								</IconButton>
							</InputAdornment>
						) : null
					}
					startAdornment={
						<InputAdornment position="start" variant="outlined">
							{name === "firstName" ||
							name === "lastName" ? null : name === "email" ? ( //<Person />
								<EmailOutlined />
							) : name === "password" ||
							  name === "confirmPassword" ? null : name === // <Lock />
							  "code" ? (
								<Password />
							) : null}
						</InputAdornment>
					}
				/>
			) : (
				<Box
					sx={{
						backgroundColor: "transparent",
						marginTop: "16px",
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						gap: "8px",
					}}
				>
					<Field
						type="checkbox"
						typeof="checkbox"
						defaultChecked={true}
						name="acceptTerms"
						id="acceptTerms"
						value={values[name]}
						size="small"
						error={touched[name] && Boolean(errors[name])}
						placeholder={placeholder}
						disabled={disabled}
						multiline={multiline}
						minRows={minRows}
						inputRef={inputRef}
						onChange={handleChange}
						onBlur={(e) => {
							formikHandleBlur(e);
						}}
						sx={{
							backgroundColor: "transparent",
							width: "24px",
							padding: "2px",
						}}
					/>

					<Label
						sx={{
							// paddingTop: "8px",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						{label}
						<span>
							<Link
								to="/tos.html"
								target="_blank"
								style={{
									cursor: "pointer",
									color: "#1472FF",
									fontWeight: 500,
									fontFamily: "Inter",
									fontSize: "16px",
									lineHeight: "24px",
									textAlign: "center",
								}}
							>
								&nbsp;Terms & Conditions
							</Link>
						</span>
					</Label>
				</Box>
			)}
			{touched[name] && Boolean(errors[name]) && (
				<ErrorFormHelperText>
					{errors[name] as string}
				</ErrorFormHelperText>
			)}
		</FormInputWrapper>
	);
};

export const FormInputWrapper = styled(Box)(({ theme }) => ({
	// width: "70%",
	// marginBottom: "16px",
	// minHeight: "40px",
}));

export const Label = styled(Typography)(({ theme }) => ({
	color: "#8A9099",
	// marginBottom: "2px",
}));

const Field = styled(OutlinedInput)(({ theme }) => ({
	width: "100%",
	height: "40px",
	background: "#A6A8AC",
	input: {
		color: theme.palette.common.black,
		padding: "0px",
		WebkitTextFillColor: `initial !important`,
		// background: "rgba(93, 99, 122, 0.1)",
	},
	label: { color: theme.palette.secondary.dark },
	"& fieldset": {
		borderRadius: "4px",
		border: "1px solid #fff",
	},
	"& input:WebkitAutofill": {
		// WebkitBoxShadow: `0 0 0 100px #fff inset !important`,
		WebkitTextFillColor: `initial !important`,
	},
}));
const ErrorFormHelperText = styled(FormHelperText)(({ theme }) => ({
	color: theme.palette.error.main,
}));
