import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Avatar from "@mui/material/Avatar";
import { Link, Stack } from "@mui/material";
import { ContentMetadata } from "./RecommendedContentProps";

interface ResultItemProps {
	icon: string;
	source: string;
	url: string;
	title: string;
	description: string;
	tag: string;
	timeAgo?: string;
	contentType?: string;
}
interface ContentIconProps {
	icon: string;
}
function SourceIcon({ icon }: ContentIconProps) {
	return (
		<Stack direction="row" alignItems="center" spacing={"4px"}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "16px",
					height: "16px",
					// bgcolor: "white",
					// borderRadius: "4px",
					// border: "1px solid",
					// borderColor: "grey.300",
					background: "white", // or 'rgba(255, 255, 255, 1)'
					border: "0.541667px solid #D2D9E4", // or '0.541667px solid rgba(210, 217, 228, 1)'
					borderRadius: "2.23656px",
					padding: "7px",
				}}
			>
				<Box
					component="img"
					src={icon}
					alt={`icon`}
					sx={{
						width: "10px",
						height: "10px",
						border: "0.541667px solid",
						borderColor: "transparent",
						borderRadius: "2.23656px",
					}}
				/>
			</Box>
		</Stack>
	);
}
const ResultItem: React.FC<ResultItemProps> = ({
	icon,
	source,
	url,
	title,
	description,
	tag,
	timeAgo,
	contentType,
}) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);
	useEffect(() => {
		if (width <= 500) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [width]);
	return (
		<ResultContainer>
			<Link
				target="_blank"
				href={url}
				sx={{
					textDecoration: "none",
					":hover #resultTitle": {
						textDecoration: "underline",
					},
				}}
			>
				<SourceInfo>
					<SourceIcon icon={icon} />
					<SourceDetails>
						<SourceName>{source}</SourceName>
						{!isMobile ? (
							<SourceUrl>
								{url && url?.substring(0, 57)}
								{url && url.length > 57 ? "..." : ""}
							</SourceUrl>
						) : (
							<SourceUrl>
								{url && url?.substring(0, 22)}
								{url && url.length > 22 ? "..." : ""}
							</SourceUrl>
						)}
					</SourceDetails>
				</SourceInfo>

				{!isMobile ? (
					<ResultTitle id="resultTitle">
						{title && title?.substring(0, 52)}
						{title && title.length > 52 ? "..." : ""}
					</ResultTitle>
				) : (
					<ResultTitle id="resultTitle">
						{title && title?.substring(0, 40)}
						{title && title.length > 40 ? "..." : ""}
					</ResultTitle>
				)}
			</Link>
			<ResultDescription>
				{description && description?.substring(0, 150)}
				{description && description.length > 150 ? "..." : ""}
			</ResultDescription>
			<Box sx={{ height: "24px" }}>
				<ContentMetadata
					author={tag ? tag + " author" : ""}
					timeAgo={timeAgo || ""}
					contentType={contentType || ""}
					showRating={true}
					url={url || ""}
					recomended={false}
				/>
			</Box>
		</ResultContainer>
	);
};

const ResultContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0px",
	// cursor: "pointer",
	[theme.breakpoints.down("lg")]: {
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		maxWidth: "100%",
	},
}));

const SourceInfo = styled(Box)(({ theme }) => ({
	display: "flex",
	gap: "4px",
	textDecoration: "none",
	[theme.breakpoints.down("lg")]: {
		// flexWrap: "wrap",
		// paddingRight: "20px",
		// marginTop: "40px",
	},
}));

// const IconWrapper = styled(Box)({
// 	borderRadius: "3.5px",
// 	backgroundColor: "#ffff",
// 	color: "#FFFFFF",
// 	display: "flex",
// 	alignItems: "center",
// 	justifyContent: "center",
// 	width: "24px",
// 	height: "24px",
// 	padding: "6px",
// 	border: "1px solid #D3D9E3",
// 	gap: "8px",
// 	// boxShadow: "0 0 3px rgba(0, 0, 0, 0.1)",
// });

const SourceDetails = styled(Box)({
	display: "flex",
	gap: "4px",
	fontSize: 12,
	color: "#4d4e53",
	whiteSpace: "nowrap",
	cursor: "pointer",
	letterSpacing: "0.02em",
	margin: "auto 0",
	"@media (max-width: 991px)": {
		whiteSpace: "initial",
	},
});

const SourceName = styled(Typography)({
	fontFamily: "Inter",
	fontWeight: 600,
	fontSize: "12px",
	"@media (max-width: 400px)": {
		fontSize: "10px",
	},
});

const SourceUrl = styled(Typography)({
	// fontFamily: "Inter",
	// fontWeight: 400,
	// fontSize: "12px",

	fontFamily: "Inter",
	fontStyle: "normal",
	fontWeight: 400,
	fontSize: "12px",
	// lineHeight: "15px",
	letterSpacing: "0.02em",
	color: "#4D4E53",
	"@media (max-width: 400px)": {
		fontSize: "10px",
	},
});

const ResultTitle = styled(Typography)(({ theme }) => ({
	fontFamily: "Inter",
	color: "#323339",
	marginTop: "4px",
	fontWeight: 600,
	fontSize: "20px",
	lineHeight: "26px",
	cursor: "pointer",
	":hover": {
		textDecoration: "underline",
	},
	[theme.breakpoints.down("lg")]: {
		maxWidth: "100%",
		// fontSize: "16px",
	},
	[theme.breakpoints.down("sm")]: {
		maxWidth: "100%",
		fontSize: "16px",
	},
	"@media (max-width: 400px)": {
		maxWidth: "100%",
		fontSize: "14px",
	},
}));

const ResultDescription = styled(Typography)({
	marginTop: "4px",
	marginBottom: "8px",
	fontFamily: "Inter",
	fontStyle: "normal",
	fontWeight: 400,
	fontSize: "14px",
	lineHeight: "17px",
	color: "#32333A",
	"@media (max-width: 991px)": {
		maxWidth: "100%",
	},
});

// const ResultTag = styled(Box)({
// 	borderRadius: "4px",
// 	backgroundColor: "#f5f7fc",
// 	alignSelf: "start",
// 	marginTop: "16px",
// 	color: "#040404",
// 	letterSpacing: "0.02em",
// 	lineHeight: "20px",
// 	padding: "2px 6px",
// 	fontWeight: 500,
// 	fontSize: "13px",
// });

export default ResultItem;
