import { Box, Link, Typography } from "@mui/material";
import FullLogoLarger from "../../svg-components/FullLogoLarger";
import Question from "../../svg-components/Question";

const TopHeading = () => {
	return (
		<Box
			sx={{
				textAlign: "center",
				marginBottom: "64px",
				marginTop: "80px",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				// gap: "16px",
			}}
		>
			<FullLogoLarger />
			<Typography
				fontWeight={"700"}
				fontSize={"24px"}
				lineHeight={"29px"}
				color={"#323339"}
				textAlign={"center"}
				fontFamily={"Inter"}
				sx={{
					marginTop: "10px",
					"@media (max-width: 400px)": {
						fontSize: "20px",
					},
				}}
			>
				Curated Library for Founders
			</Typography>
			<Link
				href="https://www.usegems.com/how-it-works"
				target="_blank"
				underline="none"
				sx={{
					marginTop: "16px",
					display: "flex",
					alignItems: "center",
					gap: "4px",
				}}
			>
				<Question />
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "14px",
						lineHeight: "17px",
						color: "#323339",
						textAlign: "center",
						fontFamily: "Inter",
					}}
				>
					How our search works
				</Typography>
			</Link>
		</Box>
	);
};

export default TopHeading;
