import React from "react";
import {
	Box,
	Typography,
	Card,
	CardHeader,
	CardActions,
	IconButton,
	Stack,
	MenuItem,
	Menu,
	Link,
} from "@mui/material";
import ThreeDotsMenu from "../../svg-components/ThreeDotsMenu";
import ThumbsDownIcon from "../../svg-components/ThumbsDownIcon";
import ThumbsUpIcon from "../../svg-components/ThumbsUpIcon";
import Tick from "../../svg-components/Tick";
import Dot from "../../svg-components/Dot";
import InfoRound from "../../svg-components/InfoRound";
// import { QuestionResource, RecomendedResources } from "../../utils/recomended";
import usePreview from "../../hooks/usePreview";
import useDataset from "../../hooks/useDataset";
import useFeedback from "../../hooks/useFeedback";
import {
	IAdditionalInfoSimilar,
	// IAdditionalInfoHome,
} from "../../interfaces/IAdditionalInfo";
import { PreviewLinkResponse } from "../../openapi";

interface ContentCardProps {
	content: {
		icon: string;
		platform: string;
		title: string;
		description: string;
		author: string;
		timeAgo: string;
		contentType: string;
		url?: string;
	};
}

interface ContentIconProps {
	icon: string;
	platform?: string;
}

interface ContentMetadataProps {
	author: string;
	timeAgo: string;
	contentType: string;
	showRating: boolean;
	url: string;
	recomended: boolean;
}

export interface RecommendationProps {
	question: string;
	setShowFunc: (value: boolean) => void;
}

export interface SourceBadgeProps {
	source: string;
	icon: string;
}

export interface MetadataProps {
	author: string;
	timeAgo: string;
	type: string;
	rating: string;
}

export function ContentMetadata({
	author,
	timeAgo,
	contentType,
	showRating,
	url,
	recomended,
}: ContentMetadataProps) {
	const { datasets } = useDataset();
	const { submitHandler } = useFeedback(datasets[0].historyId);

	const [thumbsUpActive, setThumbsUpActive] = React.useState(false);
	const [thumbsDownActive, setThumbsDownActive] = React.useState(false);
	const handleClick = async (type: string) => {
		if (!datasets || datasets.length === 0 || !datasets[0].historyId)
			return;
		const requestBody = {
			id: datasets[0].historyId,
			isHelpful: type === "thumbsUp",
			url: url || "",
			recomended: recomended,
		};
		if (
			(type === "thumbsUp" && thumbsDownActive) ||
			(type === "thumbsDown" && thumbsUpActive)
		) {
			return;
		}
		if (type === "thumbsDown") {
			setThumbsDownActive(!thumbsDownActive);
		}
		if (type === "thumbsUp") {
			setThumbsUpActive(!thumbsUpActive);
		}
		const feedBack = await submitHandler(requestBody);
		if (!feedBack) {
			if (type === "thumbsDown") {
				setThumbsDownActive(!thumbsDownActive);
			}
			if (type === "thumbsUp") {
				setThumbsUpActive(!thumbsUpActive);
			}
		}
	};
	return (
		<Stack direction="row" alignItems="center">
			{author && (
				<>
					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							gap: "4px",
						}}
					>
						<Tick />
						<Typography
							sx={{
								fontFamily: "Inter",
								fontStyle: "normal",
								fontWeight: 500,
								fontSize: "13px",
								lineHeight: "16px",
								color: "#797C80",
							}}
						>
							{author}
						</Typography>
					</Stack>
					<Box
						sx={{
							paddingX: "4px",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Dot />
					</Box>
					{/* <Dot /> */}
				</>
			)}
			{timeAgo && (
				<>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontStyle: "normal",
							fontWeight: 500,
							fontSize: "13px",
							lineHeight: "16px",
							color: "#797C80",
						}}
					>
						{timeAgo}
					</Typography>

					<Box
						sx={{
							paddingX: "4px",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Dot />
					</Box>
				</>
			)}
			{contentType && (
				<>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontStyle: "normal",
							fontWeight: 500,
							fontSize: "13px",
							lineHeight: "16px",
							color: "#797C80",
						}}
					>
						{contentType}
					</Typography>

					<Box
						sx={{
							paddingX: "4px",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Dot />
					</Box>
				</>
			)}
			{showRating && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						padding: "0px 4px",
						gap: "8px",
					}}
				>
					<div
						style={{ cursor: "pointer", height: "16px" }}
						onClick={() => {
							handleClick("thumbsUp");
						}}
					>
						<ThumbsUpIcon active={thumbsUpActive} />
					</div>
					<div
						style={{ cursor: "pointer", height: "16px" }}
						onClick={() => {
							handleClick("thumbsDown");
						}}
					>
						<ThumbsDownIcon active={thumbsDownActive} />
					</div>
				</Box>
			)}
		</Stack>
	);
}

export function ContentIcon({ icon, platform }: ContentIconProps) {
	return (
		<Stack direction="row" alignItems="center" spacing={"4px"}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: 24,
					height: 24,
					bgcolor: "white",
					borderRadius: "4px",
					border: "1px solid",
					borderColor: "grey.300",
					// boxShadow: 1,
					padding: "8px",
				}}
			>
				<Box
					component="img"
					src={icon}
					alt={`${platform} icon`}
					sx={{ width: 16, height: 16 }}
				/>
			</Box>
			{platform && (
				<Typography
					sx={{
						fontFamily: "Inter, sans-serif",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "12px",
						lineHeight: "15px",
						letterSpacing: "0.02em",
						color: "#4D4E53",
					}}
				>
					{platform}
				</Typography>
			)}
		</Stack>
	);
}

export function ContentCard({ content }: ContentCardProps) {
	return (
		<Box
			sx={{
				boxSizing: "border-box",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				padding: "16px",
				gap: "8px",
				// boxShadow: "false",
				borderRadius: "8px",
				border: "1px solid white",
				bgcolor: "#E0E7FE",
				// maxWidth: 586,
			}}
		>
			<Link
				target="_blank"
				href={content.url || "#"}
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "8px",
					textDecoration: "none",
					":hover #resultTitle": {
						textDecoration: "underline",
					},
				}}
			>
				<ContentIcon icon={content.icon} platform={content.platform} />

				<Box sx={{ padding: 0 }}>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: "18px",
							lineHeight: "22px",
							display: "flex",
							alignItems: "center",
							color: "#313339",
							marginBottom: "4px",
						}}
						id="resultTitle"
					>
						{content.title && content.title?.substring(0, 50)}
						{content.title && content.title.length > 50
							? "..."
							: ""}
					</Typography>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontStyle: "normal",
							fontWeight: 400,
							fontSize: "14px",
							lineHeight: "17px",
							color: "#32333A",
						}}
					>
						{/* {content.description} */}

						{content.description &&
							content.description?.substring(0, 165)}
						{content.description && content.description.length > 165
							? "..."
							: ""}
					</Typography>
				</Box>
			</Link>
			<ContentMetadata
				author={content.author || ""}
				timeAgo={content.timeAgo || ""}
				contentType={content.contentType || ""}
				showRating={true}
				url={content.url || ""}
				recomended={true}
			/>
		</Box>
	);
}

const ThreeDotsIcon = ({
	showFunc,
}: {
	showFunc: (value: boolean) => void;
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		showFunc(true);
	};

	return (
		<div>
			<IconButton
				id="demo-positioned-button"
				aria-controls={open ? "demo-positioned-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					height: "32px",
					width: "32px",
					"&:hover": {
						backgroundColor: "rgba(255, 255, 255, 0.3)",
						borderRadius: "4px",
					},
				}}
			>
				<ThreeDotsMenu />
			</IconButton>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorEl}
				open={open}
				onClose={() => {
					setAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{
					paddingX: "4px !important",
				}}
			>
				<MenuItem
					sx={{
						padding: "8px !important",
						backgroundColor: "#ffffff !important",
						"&:hover": {
							backgroundColor: "#EDEDED !important",
						},
					}}
					onClick={handleClose}
					selected={false}
					disableGutters={true}
				>
					Turn off this feature
				</MenuItem>
			</Menu>
		</div>
	);
};
const normalizeString = (str: string): string => {
	// Normalize by converting to lowercase and removing non-alphanumeric characters and question marks
	return str.toLowerCase().replace(/[^a-z0-9]/g, "");
};

// const findSimilarQuestion = async (
// 	inputQuestion: string
// ): Promise<QuestionResource | null> => {
// 	const normalizedInput = await normalizeString(inputQuestion);
// 	// console.log("normalizedInput", normalizedInput);
// 	// Loop through the resources array to find the most similar question
// 	for (const resource of RecomendedResources) {
// 		const normalizedQuestion = normalizeString(resource.question);
// 		// Compare the normalized input with each question
// 		if (normalizedInput === normalizedQuestion) {
// 			return resource;
// 		}
// 	}

// 	// Return null if no similar question is found
// 	return null;
// };
const findSimilarQuestionByCategory = async (
	inputQuestion: string,
	questionResources: IAdditionalInfoSimilar[]
): Promise<IAdditionalInfoSimilar | null> => {
	const normalizedInput = await normalizeString(inputQuestion);
	// console.log("normalizedInput", normalizedInput);
	// Loop through the resources array to find the most similar question

	for (const resource of questionResources) {
		const normalizedQuestion = normalizeString(resource?.similarquestion);
		// Compare the normalized input with each question
		if (normalizedInput === normalizedQuestion) {
			return resource;
		}
	}

	// Return null if no similar question is found
	return null;
};
const RecommendationCard: React.FC<RecommendationProps> = ({
	question,
	setShowFunc,
}) => {
	const { queryType, allCategoriesData } = useDataset();
	const { getPreviewPromise } = usePreview();
	const [recomendedResources, setRecomendedResources] = React.useState<
		PreviewLinkResponse[]
	>([]);
	const [desc, setDesc] = React.useState<string>("");
	React.useEffect(() => {
		const fetchSimilarQuestion = async () => {
			// console.log("fetchSimilarQuestion question", question);
			// const result = await findSimilarQuestion(question);
			const newCurrentCategory = allCategoriesData?.find(
				(category) => category.vectorClass === queryType
			);
			if (
				newCurrentCategory &&
				newCurrentCategory?.additionalInfoSimilar
			) {
				const moreAdditionalInfo: IAdditionalInfoSimilar[] =
					// eslint-disable-next-line array-callback-return
					newCurrentCategory?.additionalInfoHome.map((item) => {
						const newSimilar: IAdditionalInfoSimilar = {
							similarquestion: item.homequestion,
							similarUrl: item.homeUrl,
							similarDesc: item.homeDesc,
						};
						return newSimilar;
					});
				const additionalInfo = moreAdditionalInfo
					? [
							...moreAdditionalInfo,
							...newCurrentCategory?.additionalInfoSimilar,
					  ]
					: newCurrentCategory?.additionalInfoSimilar;

				const findSimilar = await findSimilarQuestionByCategory(
					question,
					additionalInfo
				);
				console.log("fetchSimilarQuestion result", findSimilar);
				if (!findSimilar) {
					return;
				}

				if (findSimilar && findSimilar?.similarUrl) {
					try {
						const oneResult = await getPreviewPromise(
							findSimilar?.similarUrl
						);
						// console.log(
						// 	"fetchSimilarQuestion oneResult",
						// 	oneResult
						// );
						setRecomendedResources([oneResult]);
					} catch (error) {
						console.error("fetchSimilarQuestion error", error);
					}
				}
				if (findSimilar && findSimilar?.similarDesc) {
					setDesc(findSimilar?.similarDesc);
				}
			}
		};

		fetchSimilarQuestion();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			{recomendedResources.length > 0 && (
				<Card
					sx={{
						boxShadow: "none",
						borderRadius: "8px",
						padding: 2,
						background:
							"linear-gradient(180.92deg, #EFF3FF -68.43%, #A8BFFF 177.3%)",
					}}
				>
					<CardHeader
						title={
							<Typography
								sx={{
									fontFamily: "Inter",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "18px",
									lineHeight: "22px",
									color: "#1D4C95",
								}}
							>
								Recommended for you
							</Typography>
						}
						action={<ThreeDotsIcon showFunc={setShowFunc} />}
						sx={{
							padding: "0px",
						}}
					/>
					<Box sx={{ marginY: "16px" }}>
						{recomendedResources &&
							recomendedResources.map(
								(resource: any, index: any) => (
									<ContentCard
										key={index}
										content={{
											icon: resource.favicon || "",
											platform: resource.site_name || "",
											title: resource.title,
											description:
												resource.description || "",
											author: resource.tags
												? resource.tags[0] + " author"
												: "",
											timeAgo: "",
											contentType: resource.contentTypes
												? resource.contentTypes[0]
												: "",
											url: resource.url,
										}}
									/>
								)
							)}
					</Box>
					<CardActions
						sx={{
							padding: "0px",
						}}
					>
						<Stack
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "flex-start",
								padding: "0 0 4px",
								gap: "8px",
							}}
						>
							<Box
								sx={{
									width: "16px",
									height: "16px",
								}}
							>
								<InfoRound />
							</Box>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontStyle: "normal",
									fontWeight: 500,
									fontSize: "14px",
									lineHeight: "17px",
									color: "#1D4C95",
								}}
							>
								{desc}
							</Typography>
						</Stack>
					</CardActions>
				</Card>
			)}
		</>
	);
};

interface RecommendationsSectionProps {
	setShow: (value: boolean) => void;
	question: string;
	sx?: any;
}

const RecommendationsSection: React.FC<RecommendationsSectionProps> = ({
	setShow,
	question,
	sx,
}) => {
	const recommendation = {
		setShowFunc: setShow,
		question,
	};

	return (
		<Box sx={sx}>
			<RecommendationCard {...recommendation} />
		</Box>
	);
};

export default RecommendationsSection;
